import http from 'axios';
import { getCurrentSession } from '../helpers/auth';

const setAuthHeader = async (config) => {
  const { idToken } = await getCurrentSession();
  if (idToken) {
    config.headers.Authorization = `Bearer ${idToken}`;
  }
  return config;
};

const auth = http.create({
  baseURL: `${process.env.REACT_APP_AUTH_API}auth/v1`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const web = http.create({
  baseURL: `${process.env.REACT_APP_WEB_API}web/v1`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

web.interceptors.request.use(async function (config) {
  return await setAuthHeader(config);
});

export { auth };
export default web;
