import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { push } from 'redux-first-history';
import { find, head } from 'lodash';

import { buildAsyncReducers } from '../thunkTemplate';
import {
  user as initialState,
  schedules as initialSchedulesState,
} from '../initialState';
import { getAdminUsers } from '../admin';
import { getAdminStats } from '../adminStats';
import { getOrganizationEventsStats } from '../orgStats';
import { getOrganizationApps } from '../apps';
import { loadCmmsOptions } from '../cmms';
import { loadEdgeOptions } from '../edge';
import {
  getOrganizationSchedules,
  setSelectedSchedule,
  getOrganizationScheduleEvents,
  getOrganizationScheduleDevices,
  getOrganizationScheduleResources,
} from '../schedules';
import { getOrganizationCredentials } from '../credentials';
import { getOrganizationSites } from '../sites';
import { getOrganizationDetails, setOrganizations } from '../organization';
import {
  getUser,
  setUserInfo,
  putUserSubscription,
  putUserDefaultOrganization,
} from './_user';
import { getUserAPI, putUserDefaultOrgAPI } from '../../api';
import { getOrganizationFiles } from '../uploads';
import { getOrganizationDevices } from '../devices';
import { setOrgRedirect } from '../appSettings';
import { getOrganizationResources } from '../resources';
import { getOrganizationServiceSites } from '../serviceSites';
import { getOrganizationTrends } from '../trends';
import { getOrganizationTrendCharts } from '../trendCharts';
import { getOrganizationDashboards } from '../dashboards';

const setUser = createAsyncThunk(
  'user/setUser',
  async (email, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().user;
      const { selected: prevSelectedSched } = getState().schedules;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      // Start the progressbar
      dispatch(showLoading());
      dispatch(
        setSelectedSchedule(
          prevSelectedSched?.relation_id
            ? prevSelectedSched
            : initialSchedulesState.selected
        )
      );

      // Determine what to do with the user
      if (email) {
        const {
          user: userData,
          organizations,
          relations: allRelations,
        } = await getUserAPI(email);

        // Redirect user to invite code form if there are no orgs
        if (userData && !organizations.length) {
          dispatch(push('/invite-code'));
          return { ...initialState, user: userData };
        }

        dispatch(getAdminUsers(userData));
        dispatch(getAdminStats(userData));

        const orgRedirect = getState().appSettings.orgRedirect;
        dispatch(setOrgRedirect(null));
        // if orgRedirect value is present, set as default
        let defaultOrg = find(organizations, {
          item_id: orgRedirect,
        });
        // else use user's default
        if (!defaultOrg)
          defaultOrg = find(organizations, {
            item_id: userData.default_org,
          });
        // else use first in org list
        if (!defaultOrg) defaultOrg = head(organizations) || {};

        const orgUserRelation =
          find(allRelations, {
            item_id: defaultOrg.item_id,
            relation_id: userData.item_id,
          }) || {};

        if (defaultOrg.item_id) {
          dispatch(
            setOrganizations({ all: organizations, default: defaultOrg })
          );
          dispatch(getOrganizationSchedules(defaultOrg));
          dispatch(getOrganizationCredentials(defaultOrg));
          dispatch(getOrganizationSites(defaultOrg));
          dispatch(getOrganizationDetails({ id: defaultOrg.item_id }));
          dispatch(getOrganizationScheduleEvents(defaultOrg));
          dispatch(getOrganizationScheduleDevices(defaultOrg));
          dispatch(getOrganizationScheduleResources(defaultOrg));
          dispatch(getOrganizationFiles(defaultOrg));
          dispatch(getOrganizationDevices(defaultOrg));
          dispatch(getOrganizationTrends(defaultOrg));
          dispatch(getOrganizationTrendCharts(defaultOrg));
          dispatch(getOrganizationDashboards(defaultOrg));
          dispatch(getOrganizationResources(defaultOrg));
          dispatch(getOrganizationServiceSites(defaultOrg));
          dispatch(getOrganizationEventsStats(defaultOrg));
          dispatch(getOrganizationApps(defaultOrg));
          if (defaultOrg.item_id !== userData.default_org) {
            putUserDefaultOrgAPI(userData.item_id, defaultOrg.item_id);
          }
        }

        await Promise.all([
          dispatch(loadCmmsOptions()), // Hydrate the CMMS Options
          dispatch(loadEdgeOptions()), // Hydrate the Edge Options
        ]);

        return {
          user: { ...userData, default_org: defaultOrg.item_id },
          orgUserRelation,
          allRelations,
        };
      } else {
        return { ...initialState };
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => ({ ...initialState }),
    setOrgUserRelations: (state, { payload }) => ({
      ...state,
      allRelations: payload,
      orgUserRelation: find(payload, {
        item_id: state.user.user.default_org,
      }),
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      setUser,
      setUserInfo,
      putUserSubscription,
      putUserDefaultOrganization,
      getUser,
    ]);
  },
});

// Extract each action creator by name
const { clearUser, setOrgUserRelations } = actions;

// Export the reducer, either as a default or named export
export * from './_user';
export {
  clearUser,
  setUser,
  setOrgUserRelations,
  getUser,
  putUserSubscription,
  putUserDefaultOrganization,
};
export default reducer;
