const ROUTES = {
  UNAUTH: {
    LANDING: '/',
    LOGIN: '/login',
    LOGOUT: '/logout',
    TERMS: '/terms',
    PRIVACY: '/privacy',
  },
  AUTH: {
    INVITE_CODE: '/invite-code',
    WORKSPACE: '/workspace',
    EVENTS: '/events',
    CONNECTIONS: '/connections',
    TRENDS: '/trends',
    NOTIFICATIONS: '/notifications',
    FILES: '/files',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    ORGANIZATION_ADMIN: '/organization-admin',
    ADMIN: '/admin',
  },
};

export default ROUTES;
