import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { jwtDecode } from 'jwt-decode';

/** REDUX **/
import { buildAsyncReducers } from '../thunkTemplate';
import { auth as initialState } from '../initialState';
import { setUser } from '../user';
import { addToastr, types } from '../toastr';
import { clearAppStore } from './_clear';
import { setSplashPage } from '../appSettings';
import ROUTES from '../../constants/routes';
import { removeTokens } from '../../helpers/auth/tokens';
import { getCurrentSession, redirectToAuth } from '../../helpers/auth';

const userState = async (currentSession) => ({
  authenticated: Boolean(currentSession),
  currentSession,
});

const unauthenticatedPaths = [
  '/account',
  '/login',
  '/logout',
  '/terms',
  '/privacy',
];

export const isUnauthenticatedPath = (path) =>
  unauthenticatedPaths.some((str) => path.includes(str));

const authenticateUser = createAsyncThunk(
  'auth/authenticateUser',
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setSplashPage(true));
      dispatch(showLoading());

      const currentSession = await getCurrentSession();
      const { idToken } = currentSession;

      const { email } = jwtDecode(idToken);

      await dispatch(setUser(email));

      // Prevent redirect to /workspace if setUser() redirected to /invite-code
      const { pathname } = await getState().router.location;
      if (!pathname.includes('/invite-code')) {
        dispatch(push(ROUTES.AUTH.WORKSPACE));
      }

      return await userState(currentSession);
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Whoops! Looks like there is an issue.',
          type: types.error,
          message: err,
        })
      );
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      console.error(err);
    } finally {
      dispatch(setSplashPage(false));
      dispatch(hideLoading());
    }
  }
);

const logout = createAsyncThunk('auth/logoutUser', async (_, { dispatch }) => {
  try {
    dispatch(clearAppStore());
    removeTokens();
    return initialState;
  } catch (err) {
    console.error('LOGOUT FAILED', err);
    dispatch(
      addToastr({
        title: 'Whoops! Looks like there is an issue.',
        type: types.error,
        message: err,
      })
    );
  } finally {
    redirectToAuth('logout');
  }
});

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [authenticateUser, logout]);
  },
});

const { setAuth } = actions;

// Export the reducer, either as a default or named export
export default reducer;
export { authenticateUser, logout };
