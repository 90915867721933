import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { find } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  getUserAPI,
  putUserDefaultOrgAPI,
  putUserSubscriptionAPI,
} from '../../api';

export const setUserInfo = createAsyncThunk(
  'user/setUserInfo',
  async (user, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().user;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    return { user };
  }
);

export const putUserDefaultOrganization = createAsyncThunk(
  'user/putUserDefaultOrganization',
  async ({ userId, orgId }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, user: initialUser } = getState().user;
    const organizations = getState().organization.all;

    const orgName =
      organizations.find((org) => org.item_id === orgId)?.name ?? '';

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    dispatch(
      addToastr({
        title: 'Changed Organization',
        type: types.success,
        message: orgName,
      })
    );

    putUserDefaultOrgAPI(userId, orgId);
    const user = { ...initialUser, default_org: orgId };
    return { user };
  }
);

export const putUserSubscription = createAsyncThunk(
  'user/putUserSubscription',
  async ({ relation, subscribed }, { dispatch }) => {
    try {
      dispatch(showLoading());

      const response = await putUserSubscriptionAPI(
        relation.item_id,
        relation.relation_id,
        subscribed
      );
      dispatch(
        addToastr({
          title: 'Your Organization subscription has been updated.',
          type: types.success,
        })
      );
      return { allRelations: response };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const getUser = createAsyncThunk(
  'user/getUser',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, user } = getState().user;

      if (!user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const { user: userData, relations: allRelations } = await getUserAPI(
        user.email
      );

      const orgUserRelation = find(allRelations, {
        item_id: userData.default_org,
        relation_id: userData.item_id,
      });

      return { user: userData, orgUserRelation, allRelations };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
