import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/auth';

function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return <></>;
}

export default Logout;
