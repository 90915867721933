import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import {
  init,
  replayIntegration,
  captureConsoleIntegration,
} from '@sentry/react';

import _package from '../../package.json';
import ROUTES from '../constants/routes';
import { handleAuthCode, redirectToAuth } from '../helpers/auth';
import { getTokens, setTokens, removeTokens } from '../helpers/auth/tokens';
import { authenticateUser, logout } from '../store/auth';
import Logout from './Authentication/Logout';

const Authentication = loadable(() => import('./Authentication'));
const Terms = loadable(() => import('./TermsAndPrivacy'), {
  resolveComponent: (components) => components.Terms,
});
const Privacy = loadable(() => import('./TermsAndPrivacy'), {
  resolveComponent: (components) => components.Privacy,
});

const Navigation = loadable(() => import('./MainNavigation'));

const Workspace = loadable(() => import('./Workspace'));
const Events = loadable(() => import('./Events'));
const Connections = loadable(() => import('./Connections'));
const Trends = loadable(() => import('./Trends'));
const Notifications = loadable(() => import('./Notifications'));
const Files = loadable(() => import('./Files'));
const Organization = loadable(() => import('./Organization'));
const Profile = loadable(() => import('./Profile'));
const OrganizationAdmin = loadable(() => import('./OrganizationAdmin'));
const Admin = loadable(() => import('./Admin'));

let didInit = false;

if (['staging', 'prod', 'production'].includes(process.env.REACT_APP_ENV)) {
  init({
    dsn: 'https://5ae4638cef73f5c2ebfab054d6dd089a@o240785.ingest.us.sentry.io/4507272399683584',
    environment: process.env.REACT_APP_ENV,
    release: _package.version,
    integrations: [
      replayIntegration({ blockAllMedia: false }),
      captureConsoleIntegration({ levels: ['info', 'warn', 'error'] }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function AppRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleAuthCodeGrant = async (code) => {
      try {
        await handleAuthCode(code);
        setSearchParams({});
        dispatch(authenticateUser());
      } catch (err) {
        console.error(err.message);
        redirectToAuth();
      }
    };

    const handleRefreshToken = () => {
      try {
        const _refreshToken = searchParams.get('refreshToken');
        if (_refreshToken) {
          setTokens({ RefreshToken: _refreshToken });
        }

        const { refreshToken } = getTokens();

        if (refreshToken) {
          dispatch(authenticateUser());
        } else {
          redirectToAuth();
        }
      } catch (err) {
        console.error(err);
        redirectToAuth();
      }
    };

    // React.Strict renders twice in dev
    // didInit avoids trying to authenticate twice
    if (!didInit) {
      didInit = true;

      if (
        ![
          ROUTES.UNAUTH.TERMS,
          ROUTES.UNAUTH.PRIVACY,
          ROUTES.UNAUTH.LOGOUT,
        ].some((route) => location.pathname.startsWith(route))
      ) {
        const code = searchParams.get('code');
        if (code) {
          handleAuthCodeGrant(code);
        } else {
          handleRefreshToken();
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith(ROUTES.UNAUTH.LOGOUT)) {
      dispatch(logout());
    }
  }, [location.pathname]);

  return (
    <Routes>
      {/* Unauthenticated routes */}
      <Route path={ROUTES.UNAUTH.TERMS} element={<Terms />} />
      <Route path={ROUTES.UNAUTH.PRIVACY} element={<Privacy />} />
      <Route path={ROUTES.UNAUTH.LOGOUT} element={<Logout />} />

      {/* Authenticated routes */}
      <Route path={ROUTES.AUTH.INVITE_CODE} element={<Authentication />} />

      <Route path='/' element={<Navigation />}>
        <Route index element={<Navigate to={ROUTES.AUTH.WORKSPACE} />} />

        <Route path={ROUTES.AUTH.WORKSPACE} element={<Workspace />} />
        <Route path={ROUTES.AUTH.EVENTS} element={<Events />} />
        <Route path={ROUTES.AUTH.CONNECTIONS} element={<Connections />} />
        <Route path={ROUTES.AUTH.TRENDS} element={<Trends />} />
        <Route path={ROUTES.AUTH.NOTIFICATIONS} element={<Notifications />} />
        <Route path={ROUTES.AUTH.FILES} element={<Files />} />
        <Route path={ROUTES.AUTH.SETTINGS} element={<Organization />} />
        <Route
          path={ROUTES.AUTH.ORGANIZATION_ADMIN}
          element={<OrganizationAdmin />}
        />
        <Route path={ROUTES.AUTH.ADMIN} element={<Admin />} />
        <Route path={ROUTES.AUTH.PROFILE} element={<Profile />} />
      </Route>
      {/* )} */}

      <Route path={'*'} element={<Navigate to={ROUTES.AUTH.WORKSPACE} />} />
    </Routes>
  );
}

export default AppRoutes;
